import { DateTime } from 'luxon';

export function dateFromServer(string) {
  return DateTime.fromSQL(`${string} Z`);
}

export function dateToServer(date) {
  return date && date.isValid ? date.toUTC().toSQL().substring(0, 19) : null;
}

export function dateLabel(date) {
  if (!date || !date.isValid) return false;
  if (date.hasSame(DateTime.local(), 'day'))
    return date.toLocaleString(DateTime.TIME_SIMPLE);
  if (date.hasSame(DateTime.local(), 'year'))
    return date.toLocaleString({ month: 'short', day: 'numeric' });
  return date.toLocaleString();
}

export function dateString(date) {
  if (!date || !date.isValid) return false;
  return `${date.toLocaleString(
    DateTime.DATETIME_HUGE
  )} (${date.toRelativeCalendar()})`;
}

export function recurString(obj) {
  if (!obj || obj.invalid || typeof obj !== 'object')
    return 'Invalid recurrence';
  let str = '';
  if (['day', 'week', 'month', 'year'].includes(obj.every)) {
    str = 'Every ';
    if (obj.everyX !== 1) str += `${obj.everyX || '0'} ${obj.every}s`;
    else str += obj.every;
    if (obj.every === 'week' && obj.weekdays)
      str += ` on ${obj.weekdays.map((weekday) => weekday.label).join(', ')}`;
    if (obj.every === 'year' && obj.months)
      str += ` in ${obj.months.map((month) => month.label).join(', ')}`;
    if (['month', 'year'].includes(obj.every) && obj.onthe) {
      if (obj.onthe.id === 'days' && obj.monthdays)
        str += ` on the ${obj.monthdays.map((day) => day.label).join(', ')}`;
      else if (obj.onthe.label && obj.daysExt && obj.daysExt.label)
        str += ` on the ${obj.onthe.label.toLowerCase()} ${obj.daysExt.label.toLowerCase()}`;
    }
  } else if (obj.every === 'dates' || obj.every === 'manual')
    str = 'Custom dates';
  else if (obj.every === 'times') str = 'Custom times';

  let firstDate, lastDate, countDates;
  if (obj.dates && obj.dates.length > 0) {
    firstDate = obj.dates[0];
    lastDate = obj.dates[obj.dates.length - 1];
    countDates = obj.dates.length;
  } else {
    firstDate = obj.firstDate;
    lastDate = obj.lastDate;
    countDates = obj.countDates;
  }
  if (firstDate && lastDate && countDates) {
    let fd = firstDate.isValid ? firstDate : dateFromServer(firstDate);
    let ld = lastDate.isValid ? lastDate : dateFromServer(lastDate);
    if (countDates === 1 && ld.isValid) {
      str += ` until ${ld.toLocaleString(DateTime.DATE_SHORT)}`;
    } else {
      if (fd.isValid) str += ` from ${fd.toLocaleString(DateTime.DATE_SHORT)}`;
      if (ld.isValid) str += ` to ${ld.toLocaleString(DateTime.DATE_SHORT)}`;
    }
    str += ` (${countDates} remaining recurrence${
      countDates === 1 ? '' : 's'
    })`;
  }
  return str;
}
