<template>
  <div v-if="date.isValid" :title="dateString(date)" v-text="dateLabel(date)" />
</template>

<script>
import { dateFromServer, dateLabel, dateString } from '@/utils/date';

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    date: null,
  }),

  created() {
    this.date = dateFromServer(this.value);
  },

  methods: {
    dateLabel,
    dateString,
  },
};
</script>
