<template>
  <div class="mx-auto max-w-5xl sm:px-8">
    <div class="my-8 p-4 rounded shadow bg-white max-w-lg">
      <p>Your later.io account was created:</p>
      <p>
        <later-date
          v-model="$store.state.user.created_at"
          class="font-mono text-right"
        />
      </p>
      <p>Your current timezone:</p>
      <p
        class="font-mono text-right"
        v-text="
          `${this.$store.state.currentTime.offsetNameShort} (${this.$store.state.currentTime.offsetNameLong})`
        "
      />
      <p>Current monthly cycle began:</p>
      <p>
        <later-date
          v-model="$store.state.user.count_reset_at"
          class="font-mono text-right"
        />
      </p>
      <p>Emails created this cycle:</p>
      <p
        class="font-mono text-right"
        v-text="`${$store.state.user.emails_created}`"
      />
      <p>Emails sent this cycle:</p>
      <p
        class="font-mono text-right"
        v-text="`${$store.state.user.emails_sent}`"
      />
    </div>

    <mdc-button
      class="my-8 mx-2"
      secondary
      raised
      ripple
      @click.native="newAccountDialogOpen = true"
    >
      Add New Email Account
    </mdc-button>

    <div class="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mb-12">
      <div
        v-for="account in $store.state.user.accounts"
        :key="account.hash"
        :class="account.verified ? 'bg-white' : 'bg-gray-lighter'"
        class="sm:rounded sm:shadow"
      >
        <div class="p-4">
          <div class="pb-4">
            <div
              class="text-xl"
              v-text="account.name ? account.name : account.email"
            />
            <div
              class="text-sm text-gray-dark"
              v-text="account.name ? account.email : ''"
            />
          </div>
          <div v-if="account.verified">
            <div class="pb-4">
              <h3>Email delivery</h3>
              <span
                v-text="
                  $store.state.mailerOptions.find(
                    (opt) => opt.id === account.mailer
                  ).label
                "
              />
            </div>
            <!-- <div
              v-if="account.socials && account.socials.length > 0"
              class="pb-4"
            >
              <h3>Social connections</h3>
              <div
                v-for="social in account.socials"
                :key="social.hash"
                class="flex items-center"
              >
                <later-svg
                  :path="`social/${social.type}.svg`"
                  :class="`text-${social.type}`"
                  class="w-6"
                /><span class="capitalize ml-2" v-text="social.type" />
              </div>
            </div> -->
            <mdc-button
              secondary
              raised
              class="flex ml-auto mt-auto"
              @click.native="$router.push(`/account/email/${account.hash}`)"
              v-text="'Edit'"
            />
          </div>
          <div v-else>
            <h3>Verification</h3>
            <p class="my-4">Check your email for the link.</p>
            <p class="text-sm mb-12 text-gray">
              Didn't get it? Send a blank email to
              <a href="mailto:me@later.io" class="text-primary">me@later.io</a>
              from this account, and you should get an immediate response with
              the verification link.
            </p>
            <mdc-button
              danger
              raised
              class="flex ml-auto mt-auto"
              @click.native="del(account)"
              v-text="'Delete'"
            />
          </div>
        </div>
      </div>
    </div>

    <mdc-dialog
      v-if="newAccountDialogOpen"
      type="form"
      classes="sm:w-64"
      title="Create New Account"
      @submit="submitNewAccount"
      @closing="newAccountDialogOpen = false"
    >
      <mdc-textfield
        v-model="name"
        label="Name"
        type="text"
        class="w-full"
        autocomplete="name"
      />
      <mdc-textfield
        v-model="email"
        label="Email"
        type="email"
        class="w-full"
        required
        autocomplete="username email"
      />
    </mdc-dialog>
  </div>
</template>

<script>
import axios from '@/utils/xhr';
import LaterDate from '@/components/date.vue';
import MdcButton from '@/components/mdc/button.vue';
import MdcDialog from '@/components/mdc/dialog.vue';
import MdcTextfield from '@/components/mdc/textfield.vue';

export default {
  components: {
    LaterDate,
    MdcButton,
    MdcDialog,
    MdcTextfield,
  },

  data: () => ({
    accounts: null,
    newAccountDialogOpen: false,
    name: '',
    email: '',
  }),

  methods: {
    submitNewAccount() {
      axios
        .post('/account/email', { name: this.name, email: this.email })
        .then((response) => {
          this.$store.commit('user', response.data);
          this.name = '';
          this.email = '';
          this.$store.commit(
            'snackbarMessage',
            'Check your email for the verification link.'
          );
          this.newAccountDialogOpen = false;
        });
    },

    del(account) {
      axios.delete(`/account/email/${account.hash}`).then((response) => {
        if (response.data.accounts && response.data.accounts.length >= 1) {
          // code duplicated on email.vue
          this.$store.commit('user', response.data);
          this.$store.commit(
            'snackbarMessage',
            `Account ${account.email} has been successfully deleted.`
          );
        } else {
          this.$store.commit(
            'snackbarMessage',
            'Your account has been deleted.'
          );
          this.$store.dispatch('logout', {});
        }
      });
    },
  },
};
</script>
